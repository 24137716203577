




































































































































import { RouterNames } from "@/router/routernames";
import { suplmentos_fichaDto } from "@/shared/dtos/visualizacion_dieta/base_datos_alimentos/suplmentos_fichaDto";
import { suplemento } from "@/shared/dtos/visualizacion_dieta/suplemento";
import { ssmMessageService } from "@/shared/services/message-service";
import suplementoModule from "@/store/modules/visualizacion_dieta/suplemento-module";
import { Component, Vue } from "vue-property-decorator";

@Component({
  $_veeValidate: { validator: "new" },
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    EstructuraBasica: () =>
      import("@/views/base_alimentos/estructura_basica_ficha.vue"),
  },
})
export default class Suplementos_ficha extends Vue {
  public inicializar = false;
  created() {
    if (this.is_new()) {
      suplementoModule.onGetsuplementoFichaReset(new suplmentos_fichaDto());
      this.inicializar = true;
    } else {
      suplementoModule
        .getsuplementoficha(Number.parseInt(this.$route.params.id))
        .then(() => (this.inicializar = true));
    }
  }

  mounted() {}

  public get datasource() {
    return suplementoModule.suplemento_ficha_alta;
  }

  public get editable() {
    if (this.is_new()) {
      return false;
    }
    return !this.sistema;
  }

  public get sistema() {
    return this.datasource.sistema;
  }

  public is_new() {
    return Number.parseInt(this.$route.params.id) === -1;
  }
  public cancelar() {
    this.$router.push({ name: RouterNames.suplementolista });
  }

  public eliminar() {
    if (this.is_new()) {
      ssmMessageService.toastinfo(
        "No se puede eliminar un suplemento que todavía no está creado"
      );
      return;
    }
    if (this.datasource.sistema) {
      ssmMessageService.toastinfo(
        "No se puede eliminar un suplemento que es del sistema"
      );
      return;
    }
    suplementoModule
      .eliminar_ficha_suplemento(this.datasource.id)
      .then((x) => this.$router.push({ name: RouterNames.suplementolista }));
  }

  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.is_new()) {
          suplementoModule
            .guardarsuplemento_ficha(this.datasource)
            .then((x) =>
              this.$router.push({ name: RouterNames.suplementolista })
            );
        } else {
          suplementoModule
            .modificarsuplemento_ficha(this.datasource)
            .then((x) =>
              this.$router.push({ name: RouterNames.suplementolista })
            );
        }
      }
    });
    // suplementoModule.guardarinvetario(this.datasource);
  }

  public clonar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        this.datasource.nombre = this.datasource.nombre + " - clonado";
        suplementoModule
          .guardarsuplemento_ficha(this.datasource)
          .then((x) =>
            this.$router.push({ name: RouterNames.suplementolista })
          );
      }
    });
    // suplementoModule.guardarinvetario(this.datasource);
  }
}
